export default function checkoutRemove(){
    if(document.querySelector(".checkout__item")){
        let checkoutItems = document.querySelectorAll(".checkout__item");

        checkoutItems.forEach((el) => {
            let removeButton = el.querySelector(".checkout__remove");

            removeButton.addEventListener("click", () => {
                el.style.display = "none";
            });
        });
    }
}