export default function selectLanguage(){
    if(document.querySelector(".language__choose")){
        const selectLanguage = document.querySelectorAll(".language__choose");
        selectLanguage.forEach(language => {
            language.addEventListener("click",(click)=>{
                selectLanguage.forEach((selected) => {
                    selected.classList.remove('is-active');
                });
                click.target.classList.add('is-active');
            });
        })
    }
}
