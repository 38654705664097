import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    let swiper = new Swiper(".gallery-modal-slider", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: ".banner-button-next",
            prevEl: ".banner-button-prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let offersAvailable = new Swiper(".offers-available__items", {
        watchOverflow: true,
        slidesPerView: 5,
        spaceBetween: 30,
        navigation: {
            nextEl: ".offer-button-next",
            prevEl: ".offer-button-prev",
        },
        pagination: {
            el: ".offers-pagination",
            type: "bullets",
            clickable: true,
        },
        breakpoints: {
            1199: {
                slidesPerView: 3.4,
            },
            900: {
                slidesPerView: 2.4,
            },
            600: {
                slidesPerView: 1.4,
            },
        },
    });

    let mainSlide = new Swiper(".banner__slide", {
        navigation: {
            nextEl: ".banner__next",
            prevEl: ".banner__prev",
        },
        pagination: {
            clickable: true,
            el: ".banner__pagination",
        },
    });

    let bannerCupon = new Swiper(".banner-swiper", {
        watchOverflow: true,
        navigation: {
            nextEl: ".cupon-button-next",
            prevEl: ".cupon-button-prev",
        },
    });

    let bannerCuponDesktop = new Swiper(".banner-cupon-desktop", {
        watchOverflow: true,
        navigation: {
            nextEl: ".cupon-button-next",
            prevEl: ".cupon-button-prev",
        },
    });

    let offers = new Swiper(".establishments__content.mobile", {
        watchOverflow: true,
        slidesPerView: 5,
        spaceBetween: 30,
        pagination: {
            el: ".offers-pagination",
            type: "bullets",
            clickable: true,
        },
        breakpoints: {
            1199: {
                slidesPerView: 3.4,
            },
            900: {
                slidesPerView: 2.4,
            },
            600: {
                slidesPerView: 1.4,
            },
        },
    });
};
