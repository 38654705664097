export default function selectLocation() {
    if (document.querySelector(".location__item")) {
        let locationItem = document.querySelectorAll(".location__item");

        locationItem.forEach(el => {
            el.addEventListener("click", () => {
                locationItem.forEach(e => {
                    e.classList.remove("is-active");
                });

                el.classList.add("is-active");
            });
        });
    }
}
