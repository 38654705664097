export default function customSelect(){
    if(document.querySelector(".custom-select-js")){
        let select = document.querySelectorAll(".custom-select-js");
        
        select.forEach((el) => {
            let selectHead = el.querySelector(".select-head-js");
            let headText = el.querySelector(".select-head-js p")
            let selectOptions = el.querySelector(".select-options-js");
            let selectOption = el.querySelectorAll(".select-option-js p");
    
            selectHead.addEventListener("click", () => {
                selectOptions.classList.toggle("is-active");
            });

            selectOption.forEach((option) => {
                option.addEventListener("click", () => {
                    headText.innerHTML = option.innerHTML;
                    selectOptions.classList.remove("is-active");
                });
            });
        });
    }
}