export default function dataEditor(){
    if(document.querySelector(".data__edit")){
        let dataField = document.querySelectorAll(".data__info");

        dataField.forEach((el) => {
            let editButton = el.querySelector(".data__button button");
            let editField = el.querySelector(".data__edit");

            editButton.addEventListener("click", () => {
                if(editButton.innerHTML == "Cancelar"){
                    editButton.innerHTML = "Editar";
                }else{
                    editButton.innerHTML = "Cancelar";
                }

                editField.classList.toggle("is-active");
            });
        });
    }
}