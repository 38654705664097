export default function loading() {
    if(window.innerWidth < 1200) {
        let $loading = document.querySelector(".loading");
        let $clickInTagA = document.querySelectorAll("a");

        $clickInTagA.forEach(a => {
            a.addEventListener("click", () => {
                if(!a.hasAttribute("target") && a.href && a.attributes[0].value !== "#") {
                    $loading.classList.add("is-active");
                }
            });
        });

        document.querySelector("DOMContentLoaded", () => {
            $loading.classList.remove("is-active");
        });
    }
}
