import {Html5QrcodeScanner} from "html5-qrcode";

export default function qrCode() {
    let lastPage;
    let currentPage;

    function onScanSuccess(decodedText, decodedResult) {
        currentPage = decodedText;

        if(currentPage !== lastPage) {
            window.location.assign(decodedText);

            lastPage = decodedText;
        }
    }

    function onScanFailure(error) {
        console.log(`Code scan error = ${error}`);
        // DEPOIS CRIAR REGRA PRA QUANDO DER ERRO IR PRA PAGINA 404
    }

    let html5QrcodeScanner = new Html5QrcodeScanner("reader",
        {
            fps: 10,
            qrbox: {
                width: 250,
                height: 250
            }
        },
    /* verbose= */ false);

    html5QrcodeScanner.render(onScanSuccess, onScanFailure);
}
